// src/data/users.js
const initialUsers = [
    {
        display_name: "初音ミク",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_miku.jpg"
    },
    {
        display_name: "鏡音リン",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_rin.jpg"
    },
    {
        display_name: "鏡音レン",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_len.jpg"
    },
    {
        display_name: "巡音ルカ",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_luka.jpg"
    },
    {
        display_name: "KAITO",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_kaito.jpg"
    },
    {
        display_name: "MEIKO",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_meiko.jpg"
    },
    {
        display_name: "おしゃべリンちゃん",
        icon_image_url: "https://mikucrossing.com/mine_ai/icon/mine_ai_rin.jpg"
    },
];

export default initialUsers;