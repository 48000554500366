import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
    TextField, Button, Container, CssBaseline, Typography, MenuItem, Select, InputLabel, FormControl, Grid
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import initialUsers from './data/users';
import initialNotifications from './data/notifications';

const messageTemplates = {
    follow: '<span class="user-name">{user}</span> さんと他 {count} 人にフォローされました',
    like: '<span class="user-name">{user}</span> さんがあなたのポストをいいねしました',
    reply: '<span class="user-name">{user}</span> さんがあなたの返信をいいねしました',
    like_multiple: '<span class="user-name">{user}</span> さんと他 {count} 人があなたのポストをいいねしました',
    retweet: '<span class="user-name">{user}</span> さんがあなたのポストをリツイートしました',
    follow_multiple: '<span class="user-name">{user}</span> さんと他 {count} 人にフォローされました',
    reply_multiple: '<span class="user-name">{user}</span> さんと他 {count} 人があなたの返信をいいねしました',
    retweet_multiple: '<span class="user-name">{user}</span> さんと他 {count} 人があなたのポストをリツイートしました'
};

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#000',
            paper: '#1c1c1c'
        },
        primary: {
            main: '#1DA1F2'
        }
    }
});

const Header = () => (
    <>
        {/*<div className="header">*/}
        {/*    <div className="time">22:01</div>*/}
        {/*    <img src="https://mikucrossing.com/mine_ai/icon/mine_ai_miku.jpg" alt="User Icon" />*/}
        {/*    <div className="settings">⚙️</div>*/}
        {/*</div>*/}
        <div className="header-title">
            Twitter 通知シミュレーター
        </div>
    </>
);

const Notification = ({ notification }) => {
    const userIcons = notification.users.slice(0, 6).map((user, index) => (
        <img key={index} src={user.icon_image_url} alt={user.display_name} />
    ));

    const actionIconClass = `fas fa-${notification.actionIcon} action-icon ${notification.actionIcon}`;

    let message;
    if (notification.users.length > 1) {
        message = messageTemplates[`${notification.type}_multiple`]
            .replace('{user}', notification.users[0].display_name)
            .replace('{count}', notification.users.length - 1);
    } else {
        message = messageTemplates[notification.type]
            .replace('{user}', notification.users[0].display_name)
            .replace('{count}', notification.users.length - 1);
    }

    return (
        <div className="notification">
            <div className="top-row">
                <div className={actionIconClass}></div>
                <div className="icons">
                    {userIcons}
                </div>
            </div>
            <div className="content">
                <p className="message" dangerouslySetInnerHTML={{ __html: message }}></p>
                {notification.content && <p className="gray-text">{notification.content}</p>}
            </div>
        </div>
    );
};

const Notifications = ({ notifications }) => (
    <div className="notifications">
        {notifications.map((notification, index) => (
            <Notification key={index} notification={notification} />
        ))}
    </div>
);

const UserForm = ({ addUser }) => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && image) {
            const newUser = {
                display_name: name,
                icon_image_url: URL.createObjectURL(image)
            };
            addUser(newUser);
            setName('');
            setImage(null);
        }
    };

    return (
        <Container component="main" maxWidth="xs" className="container-section">
            <CssBaseline />
            <div>
                <Typography component="h1" variant="h5">
                    キャラクター追加
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="キャラクター名"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                        アイコンを選択
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        追加
                    </Button>
                </form>
            </div>
        </Container>
    );
};

const NotificationForm = ({ addNotification, users }) => {
    const [type, setType] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [content, setContent] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (type && selectedUsers.length > 0) {
            const newNotification = {
                type,
                users: selectedUsers,
                content: content || undefined,
                actionIcon: type === 'like' ? 'heart' :
                    type === 'follow' ? 'user-plus' :
                        type === 'retweet' ? 'retweet' :
                            'reply'
            };
            addNotification(newNotification);
            setType('');
            setSelectedUsers([]);
            setContent('');
        }
    };

    return (
        <Container component="main" maxWidth="xs" className="container-section">
            <CssBaseline />
            <div className="notification-form">
                <Typography component="h1" variant="h5">
                    通知を編集
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="type-label">タイプ</InputLabel>
                        <Select
                            labelId="type-label"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            required
                        >
                            <MenuItem value="like">いいね</MenuItem>
                            <MenuItem value="reply">返信</MenuItem>
                            <MenuItem value="follow">フォロー</MenuItem>
                            <MenuItem value="retweet">リツイート</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="user-label">ユーザー</InputLabel>
                        <Select
                            labelId="user-label"
                            multiple
                            value={selectedUsers}
                            onChange={(e) => setSelectedUsers(e.target.value)}
                            renderValue={(selected) => selected.map(user => user.display_name).join(', ')}
                            required
                        >
                            {users.map((user, index) => (
                                <MenuItem key={index} value={user}>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <img src={user.icon_image_url} alt={user.display_name} style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 8 }} />
                                        </Grid>
                                        <Grid item>{user.display_name}</Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="content"
                        label="内容"
                        name="content"
                        autoComplete="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        追加
                    </Button>
                </form>
            </div>
        </Container>
    );
};

const App = () => {
    const [users, setUsers] = useState(initialUsers);
    const [notifications, setNotifications] = useState(initialNotifications);

    const addUser = (newUser) => {
        setUsers([...users, newUser]);
    };

    const addNotification = (newNotification) => {
        setNotifications([newNotification, ...notifications]);
    };

    const resetNotifications = () => {
        setNotifications([]);
    };

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <UserForm addUser={addUser} />
            <NotificationForm addNotification={addNotification} users={users} />
            <Container component="main" maxWidth="xs" className="reset-button-container">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={resetNotifications}
                    fullWidth={false}
                    style={{ marginTop: '10px', width: '100%' }}
                >
                    通知をリセット
                </Button>
            </Container>
            <Notifications notifications={notifications} />
        </ThemeProvider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));

