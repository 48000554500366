// src/data/notifications.js
import initialUsers from './users';

const initialNotifications = [
    {
        type: 'follow',
        users: [
            initialUsers[0],
            initialUsers[1],
            initialUsers[2],
            initialUsers[3],
            initialUsers[4],
            initialUsers[5]
        ],
        actionIcon: 'user-plus'
    },
    {
        type: 'like',
        users: [
            initialUsers[2]
        ],
        content: 'めったにない九州での展覧会、駆け込みで改めて16年間のキセキに感動しちゃいました💙👏 #グリーンランド #ニコニコ動画と初音ミクのキセキ',
        actionIcon: 'heart'
    },
    {
        type: 'reply',
        users: [
            initialUsers[4]
        ],
        content: 'えっっ！？次のミククロは福岡で開催するんですか！？！？',
        actionIcon: 'reply'
    },
    {
        type: 'like',
        users: [
            initialUsers[0],
            initialUsers[1],
            initialUsers[3]
        ],
        content: '...超会議でのミククロ05どうでしたか？💕 会場に来れなかった方のために、BOOTHでグッズの頒布を開始します！🙌✨\n※数に限りがあるので、どうぞお早めに💨 #MIKUCrossing #ミククロ #ニコニコ超会議2024',
        actionIcon: 'heart'
    },
    {
        type: 'retweet',
        users: [
            initialUsers[1],
            initialUsers[5]
        ],
        content: '初音ミクのライブをリツイートしました。',
        actionIcon: 'retweet'
    }
];

export default initialNotifications;